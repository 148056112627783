@import url('https://fonts.googleapis.com/css2?family=Fira+Code:wght@300;400;500;600;700&display=swap');

:root {
  --background-color: #FFF;
  --foreground-color: #ff1a3c; // ea1837
  --text-color: #1f2229;
}

// Dark mode colors
@media (prefers-color-scheme: dark) {
  :root {
    --background-color: #1f2229;
    --foreground-color: #ff1a3c; // ea1837
    --text-color: #FFF;
  }
}

* {
  box-sizing: border-box;

  &::selection {
    color: var(--background-color);
    background: var(--foreground-color);
  }

  &::-moz-selection {
    color: var(--background-color);
    background: var(--foreground-color);
  }
}

html, body {
  margin: 0;
  padding: 0;

  width: 100%;
  height: 100%;

  background-color: var(--background-color);
  color: var(--text-color);

  font-family: "Fira Code", Monaco, Consolas, "Ubuntu Mono", monospace;
}

#root {
  width: 100%;
  max-width: 820px;
  margin: 0 auto;
  padding: 40px 40px 50px;
}

header {
  width: 100%;
  display: flex;
  flex-direction: row;
  background-image: repeating-linear-gradient(90deg, var(--foreground-color), var(--foreground-color) 2px, transparent 0px, transparent 8px);
  justify-content: space-between;

  .title {
    background-color: var(--foreground-color);
    color: var(--background-color);

    padding: 4px 7px 4px 8px;
    font-size: 1em;
    line-height: 28px;

    text-decoration: none;

    font-weight: 400;
  }

  #language-switcher {
    display: block;
    margin: 0;
    padding: 0;

    flex-shrink: 0;

    a {
      display: inline-block;
      padding: 4px 8px;

      background-color: var(--background-color);
      border: 2px solid var(--foreground-color);

      line-height: 24px;
      cursor: default;
      color: var(--foreground-color);
      text-decoration: none;
      font-weight: 300;

      &:hover {
        color: var(--background-color);
        background-color: var(--foreground-color);
        font-weight: 400;
      }

      //&.active {
      //  color: var(--background-color);
      //  background-color: var(--foreground-color);
      //  font-weight: 400;
      //}
    }
  }
}

menu {
  display: block;
  width: 100%;
  margin: 15px 0 0 0;
  padding: 0;

  a {
    position: relative;
    padding: 0 .75em;
    margin-right: 25px;
    line-height: 28px;
    background-color: var(--background-color);
    color: var(--text-color);
    text-decoration: none;

    cursor: default;

    &.active,
    &:hover {
      color: var(--foreground-color);
      font-weight: bold;

      &:before {
        position: absolute;
        left: -2px;
        content: "[";
        color: var(--foreground-color);
        font-weight: bold;
      }

      &:after {
        position: absolute;
        content: "]";
        right: -2px;
        color: var(--foreground-color);
        font-weight: bold;
      }
    }
  }
}

figure {
  figcaption {
    font-size: .8em;
    font-style: italic;
  }
}

footer {
  margin-top: 50px;
  border-top: 3px dotted var(--foreground-color);

  font-size: .9em;
  text-align: center;

  &:before {
    content: "";
    display: block;
    width: 100%;

    margin: 2px 0 45px 0;
    border-top: 3px dotted var(--foreground-color);
  }

  .contact-me-plz {
    display: block;
    text-decoration: none;

    color: var(--foreground-color);

    .w {
      color: var(--text-color);
      padding: 0 2px;
    }
  }
}

main {
  width: 100%;

  h1 {
    margin: 60px 0 30px;

    font-size: 1.2em;
    color: var(--foreground-color);
    font-weight: 600;

    border-bottom: 3px dotted var(--foreground-color);

    &:after {
      content: "";
      display: block;
      width: 100%;

      margin: 10px 0 2px 0;
      border-bottom: 3px dotted var(--foreground-color);
    }

    .back-arrow {
      float: right;
      transition-duration: .15s;

      color: var(--foreground-color);
      text-decoration: none;

      &:hover {
        padding-right: .4em;
        font-weight: 700;

        span {
          font-weight: 500;
        }
      }

      &:active {
        padding-right: 1em;
      }

      span {
        padding-left: .5em;
        font-size: .8em;
        //vertical-align: top;
        font-weight: 500;
      }
    }
  }

  h2 {
    position: relative;
    margin-top: 40px;
    font-weight: 700;
    font-size: 1.2em;

    &:before {
      content: "\2591";
      position: absolute;
      top: -2px;
      left: -20px;

      color: var(--foreground-color);
      cursor: default;
    }
  }

  h4 {
    margin: 30px 0 0 0;
  }

  p {
    font-size: .95em;
    line-height: 1.7em;
    font-weight: 400;
    text-indent: 2em;

    a {
      color: var(--text-color);

      text-decoration: none;
      border-bottom: 2px solid var(--foreground-color);
      cursor: default;

      &:hover {
        color: var(--foreground-color);
        font-weight: 600;
      }

      &.open-arrow {
        border: none;
        color: var(--foreground-color);
        font-size: 1.3em;
        padding-left: .2em;
        font-weight: 300;
        vertical-align: bottom;
        margin-left: .5em;

        transition-duration: .15s;

        &:hover {
          padding-left: .4em;
          font-weight: 700;

          span {
            font-weight: 500;
          }
        }

        &:active {
          padding-left: 1em;
        }

        span {
          padding-left: .5em;
          font-size: .8em;
          vertical-align: bottom;
          font-weight: 400;
        }
      }
    }

  }

  ul {
    list-style-type: none;
    margin-top: 5px;

    li {
      position: relative;
      width: fit-content;
      text-decoration-style: dotted;
      text-decoration: var(--foreground-color) ;

      &:before {
        content: "_";
        position: absolute;
        left: -.7em;

        color: var(--foreground-color);
        cursor: default;
      }

      &>.wtf-comment,
      &>.serious-comment {
        display: none;
      }

      &:hover>.wtf-comment,
      &:hover>.serious-comment {
        display: inline;
      }

      .serious-comment {
        position: absolute;
        color: var(--foreground-color);
        white-space: nowrap;
        left: 100%;

        &:before {
          content: "::"
        }
      }

      .wtf-comment {
        position: absolute;
        color: var(--foreground-color);
        transform-origin: left center;
        white-space: nowrap;

        &.wtf-1 {
          left: 100%;
          transform: rotate(-2deg);
        }

        &.wtf-2 {
          left: 50%;
          top: -70%;
          transform: rotate(1.5deg);
        }

        &.wtf-3 {
          left: 100%;
          transform: rotate(1deg);
        }

        &.wtf-4 {
          left: 80%;
          top: -70%;
          transform: rotate(4deg);
        }

        &.wtf-5 {
          left: 100%;
          transform: rotate(-1deg);
        }
      }
    }
  }
}

.comment {
  display: inline-block;
  position: relative;
  margin-left: 40px;

  font-size: .8em;
  font-style: italic;

  vertical-align: top;
  padding-top: .2em;

  &:before {
    content: "//";
    position: absolute;
    left: -20px;
  }
}

.cv-row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 100px 0;
  font-size: 1.1em;

  .pdf-link {
    color: var(--foreground-color);
    font-weight: 300;
    text-decoration: none;
    flex-shrink: 0;

    &:hover {
      font-weight: 500;
    }
  }

  span {
    display: block;
    width: 50%;
    letter-spacing: -.2em;
    padding-bottom: .1em;
    cursor: default;

    &:first-child {
      text-align: right;
      margin-right: 1.15em;
    }

    &:last-child {
      margin-left: 1em;
    }
  }
}

.thumbs-row {
  width: calc(100% + 5px);
  display: flex;
  flex-direction: row;
  justify-content: left;
  margin: 25px 0 45px -5px;
  flex-wrap: wrap;

  img {
    display: block;
    height: 100px;
    align-self: center;
    margin: 0 5px 10px;

    &.bordered {
      border: 1px double rgba(0, 0, 0, .25);
    }
  }
}

figure {
  margin-bottom: 2.5em;

  figcaption {
    text-align: center;

    a {
      color: var(--text-color);

      text-decoration: none;
      border-bottom: 2px solid var(--foreground-color);
      cursor: default;

      &:hover {
        color: var(--foreground-color);
        font-weight: 600;
      }
    }
  }
}

.full-width-image {
  width: 100%;
}

.images-row {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: left;

  figure {
    margin-left: .5em;
    margin-right: .5em;

    img {
      max-width: 100%;
    }
  }
}
